var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user !== null
    ? _c("div", { staticClass: "flex flex-col" }, [
        _c(
          "nav",
          {
            staticClass:
              "flex flex-row justify-between px-3 mt-3 pb-2 border-b border-blue-500 items-end"
          },
          [
            _c("p", {
              staticClass: "font-semibold w-full ml-12 text-center",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("the_account_detail.label_user_profile")
                )
              }
            }),
            _c("router-link", {
              staticClass:
                "bg-blue-500 text-white rounded-full py-1 px-3 focus:outline-none focus:shadow",
              attrs: { to: { name: "account-edit" } },
              domProps: { textContent: _vm._s(_vm.$t("general.button_edit")) }
            })
          ],
          1
        ),
        _c("div", { staticClass: "flex-grow" }, [
          _c(
            "div",
            {
              staticClass:
                "w-full flex justify-center py-6 border-b border-blue-500"
            },
            [
              _vm.user.avatar !== null
                ? _c("img", {
                    staticClass: "h-24 rounded-full",
                    attrs: {
                      src: _vm.user.avatar.medium,
                      alt: _vm.$t("general.label_profile_picture")
                    }
                  })
                : _c("img", {
                    staticClass: "h-24 rounded-full w-full",
                    attrs: {
                      src: require("@/assets/images/avatar_placeholder.svg"),
                      alt: _vm.$t("general.label_profile_picture")
                    }
                  })
            ]
          ),
          _c("div", { staticClass: "detail-field" }, [
            _c("p", {
              staticClass: "detail-field-label",
              domProps: {
                textContent: _vm._s(_vm.$t("the_account_detail.label_name"))
              }
            }),
            _c("p", {
              domProps: {
                textContent: _vm._s(
                  (_vm.user.firstname || "") + " " + (_vm.user.lastname || "")
                )
              }
            })
          ]),
          _c("div", { staticClass: "detail-field" }, [
            _c("p", {
              staticClass: "detail-field-label",
              domProps: {
                textContent: _vm._s(_vm.$t("the_account_detail.label_role"))
              }
            }),
            Object.values(_vm.UserRoles).indexOf(_vm.user.role) >= 0
              ? _c("p", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("user_roles." + _vm.user.role))
                  }
                })
              : _vm._e()
          ]),
          _vm.user.phone
            ? _c("div", { staticClass: "detail-field" }, [
                _c("p", {
                  staticClass: "detail-field-label",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("the_account_detail.label_phone")
                    )
                  }
                }),
                _c("p", { domProps: { textContent: _vm._s(_vm.user.phone) } })
              ])
            : _vm._e(),
          _vm.user.email
            ? _c("div", { staticClass: "detail-field" }, [
                _c("p", {
                  staticClass: "detail-field-label",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("the_account_detail.label_email")
                    )
                  }
                }),
                _c("p", { domProps: { textContent: _vm._s(_vm.user.email) } })
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "flex flex-col items-end justify-end my-5 mr-4" },
            [
              _c("router-link", {
                staticClass:
                  "btn btn-gray font-semibold py-2 px-4 rounded-full mb-4",
                attrs: { to: { name: "account-password-edit" } },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("the_account_detail.button_update_password")
                  )
                }
              }),
              _c("button", {
                staticClass:
                  "btn btn-gray font-semibold py-2 px-4 rounded-full",
                class: {
                  "btn-loading": _vm.loading,
                  "text-red-500": !_vm.loading
                },
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("the_account_detail.button_logout")
                  )
                },
                on: { click: _vm.logout }
              })
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }