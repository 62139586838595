<template>
  <div
    class="flex flex-col"
    v-if="user !== null">
    <nav class="flex flex-row justify-between px-3 mt-3 pb-2 border-b border-blue-500 items-end">
      <p
        class="font-semibold w-full ml-12 text-center"
        v-text="$t('the_account_detail.label_user_profile')">
      </p>
      <router-link
        :to="{ name: 'account-edit' }"
        class="bg-blue-500 text-white rounded-full py-1 px-3 focus:outline-none focus:shadow"
        v-text="$t('general.button_edit')">
      </router-link>
    </nav>
    <div class="flex-grow">
      <div class="w-full flex justify-center py-6 border-b border-blue-500">
        <img
          v-if="user.avatar !== null"
          :src="user.avatar.medium"
          :alt="$t('general.label_profile_picture')"
          class="h-24 rounded-full"
        >
        <img
          v-else
          src="@/assets/images/avatar_placeholder.svg"
          :alt="$t('general.label_profile_picture')"
          class="h-24 rounded-full w-full"
        >
      </div>
      <div class="detail-field">
        <p
          v-text="$t('the_account_detail.label_name')"
          class="detail-field-label"></p>
        <p v-text="`${user.firstname || ''} ${user.lastname || ''}`"></p>
      </div>
      <div class="detail-field">
        <p
          v-text="$t('the_account_detail.label_role')"
          class="detail-field-label"></p>
        <p
          v-if="Object.values(UserRoles).indexOf(user.role) >= 0"
          v-text="$t(`user_roles.${user.role}`)"
        ></p>
      </div>
      <div
        v-if="user.phone"
        class="detail-field">
        <p
          v-text="$t('the_account_detail.label_phone')"
          class="detail-field-label"></p>
        <p v-text="user.phone"></p>
      </div>
      <div
        v-if="user.email"
        class="detail-field">
        <p
          v-text="$t('the_account_detail.label_email')"
          class="detail-field-label"></p>
        <p v-text="user.email"></p>
      </div>
      <div class="flex flex-col items-end justify-end my-5 mr-4">
        <router-link
          v-text="$t('the_account_detail.button_update_password')"
          class="btn btn-gray font-semibold py-2 px-4 rounded-full mb-4"
          :to="{ name: 'account-password-edit' }"
        ></router-link>
        <button
          v-text="$t('the_account_detail.button_logout')"
          class="btn btn-gray font-semibold py-2 px-4 rounded-full"
          @click="logout"
          :class="{'btn-loading': loading, 'text-red-500': !loading}"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
  import UserRoles from '@/constants/UserRoles';

  export default {
    name: 'TheAccountDetail',
    data() {
      return {
        UserRoles,
        loading: false,
      };
    },
    computed: {
      user() {
        return this.$store.getters['auth/user'];
      },
    },
    methods: {
      logout() {
        this.loading = true;
        this.$store.dispatch('auth/logout').then(() => {
          this.$router.push({ name: 'login' });
        });
      },
    },
  };
</script>
